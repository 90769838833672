<template>
  <PortalBlock :portal-block="4"></PortalBlock>
</template>

<script>
import PortalBlock from "./components/PortalBlock.vue";

export default {
  name: "portal-contact-us",
  components: {
    PortalBlock,
  },
};
</script>
